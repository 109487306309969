import { Skeleton } from '@mui/material';
import React, { useState } from 'react';
import SidebarEntry from './sidebarEntry';

export class SidebarFunctions {
  static scrollToTop() {
    window.scrollTo(0, 0)
  }
}

export function toggleRespNavigation(): void {
  // Show links 
  const elem: HTMLElement = document.getElementsByClassName("links")[0]! as HTMLElement;
  elem!.style.display = elem!.style.display === "block" ? "none" : "block";
}

function Sidebar(props: any) {
  const [expanded, setExpanded] = useState(false);
  const [imageLoaded, setImageLoaded] = useState(false)
  function toggleExpanded() {
    setExpanded(!expanded)
    toggleRespNavigation();
  }
  return (
    <div className={expanded ? "expanded navbar" : "navbar"} id="nav">
      <a className="img-link">
        <img id="myimg" style={imageLoaded ? { visibility: 'visible' } : { visibility: "hidden" }} src="https://avatars1.githubusercontent.com/u/49651456?s=400&u=358f4df877e71294504a3baadf795e963cd54de3&v=4" className="profile-picture" onLoad={() => setImageLoaded(true)} />
        <Skeleton className="mui-skeleton" variant='circular' animation="pulse" style={imageLoaded ? { visibility: "hidden", transition: "none" } : { height: "150px", width: "150px", margin: "0 auto" }} sx={{ bgcolor: 'grey.700' }}
        />
        <span className="nav-name">
          <button onClick={() => toggleExpanded()} className="navbar-toggle">Menu</button>
          <span className="owner-name">
            {props.name}
          </span>
        </span>
      </a>
      <ul className="links">
        <SidebarEntry name="Start" idlink="#" hook={toggleExpanded}></SidebarEntry>
        <SidebarEntry name="Lebenslauf" idlink="#cv" hook={toggleExpanded}></SidebarEntry>
        <SidebarEntry name="Portfolio" idlink="#pf" hook={toggleExpanded}></SidebarEntry>
        <SidebarEntry name="Technologie-Stack" idlink="#tech-stack" hook={toggleExpanded}></SidebarEntry>
      </ul>
    </div>
  );
}

export default Sidebar;