import React from 'react';
import Iconbar from './iconbar';
import HLine from './horizontalLine';
import Content from '../content/content';

type TitleProps = {
    name:string,
    firstname:string,
    address?:string,
    email:string,
    id: string,
    summary?:string 
}
function TitleView(props:TitleProps){
    return (
        <div className="titleView" id={props.id}>
            {Content.profilePic("sidebar-pic")}
            <h1 className="title0">{props.firstname} {props.name}</h1>
            <p className="summaryText">{props.summary}</p>
            <div className = "subheadingTitle">
                <a className = " boxedEffect" href={"mailto:"+props.email}>{props.email}</a>
            </div>
            <Iconbar/>
        </div>
      );
}

export default TitleView;