import React from 'react';

type CVProps ={
   title?:string,
   company: string,
   dateRange: string,
   text?: string
}
function CVEntry(content:CVProps){
    return (
        <div className="cvEntry">
            <div className="cv-content">
                <h3 className="jobTitle">{content.title}</h3>
                <div className="companyName">{content.company}</div>
                <p className ="cv-text">{content.text}</p>
            </div>
            <div className="date-range">
                <p>{content.dateRange}</p>
            </div>
        </div>
      );
}

export default CVEntry;