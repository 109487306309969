import { Grid } from "@material-ui/core";
import { Button } from "@mui/material";
import React, { useEffect, useState } from "react";
import Content from "../content/content";
import InfoCard from "./cards/card";
import ChapterTitle from "./chapterTitle";
import CVEntry from "./cvEntry";
import HLine from "./horizontalLine";
import { Impressum } from "./impressum";
import Section from "./section";
import { Datenschutz } from './datenschutz';

function MainView() {
  const [impressumOpen, setImpressumOpen] = useState<boolean>(false)
  const [datenschutzOpen, setDatenschutzOpen] = useState(false)

  const footerStyle: React.CSSProperties = {
    fontSize: "9pt",
    margin: "0 10px auto",
    float: "left"
  };

  const handleOpenImpressum = () => {
    setImpressumOpen(true)
  }
  const handleOpenDatenschutzerklärung = () => {
    setDatenschutzOpen(true)
  }

  const handleCloseImpressum = () => {
    setImpressumOpen(false);
  }

  const handleCloseDatenschutz = () => {
    setDatenschutzOpen(false);
  }

  useEffect(() => {
    console.log(impressumOpen);

  }, [impressumOpen])

  return (
    <div className="main-container">
      <HLine />
      <Section sectionId="cv">
        <ChapterTitle name="Lebenslauf"></ChapterTitle>
        {Content.cvEntries.map((i, j) => {
          return (
            <CVEntry
              title={i.jobTitle}
              company={i.company}
              dateRange={i.dateRange}
              text={i.entryText}
            ></CVEntry>
          );
        })}
        <HLine />
      </Section>
      <Section sectionId="edu">
        <ChapterTitle name="Bildung"></ChapterTitle>
        {Content.eduEntries.map((i, j) => {
          return (
            <CVEntry
              title={i.jobTitle}
              company={i.company}
              dateRange={i.dateRange}
              text={i.entryText}
            ></CVEntry>
          );
        })}
        <HLine />
      </Section>
      <Section sectionId="pf">
        <ChapterTitle name="Portfolio / Blog"></ChapterTitle>
        <Grid
          container
          direction="row"
          justify="center"
          alignItems="flex-start"
        >
          {Content.portfolioEntries.map((i, j) => {
            return (
              <InfoCard
                image={i.image}
                title={i.title}
                description={i.shortDescription}
                textDokumentation={i.textDokumentation}
              />
            );
          })}
        </Grid>
        <HLine />
      </Section>
      <Section sectionId="tech-stack">
        <ChapterTitle name="Technologie-Stack" />
        {Content.techStackEntries.map((i, j) => {
          return (
            <CVEntry
              title={i.jobTitle}
              company={i.company}
              dateRange=""
              text={i.entryText}
            ></CVEntry>
          );
        })}
        <HLine />

        <Impressum open={impressumOpen} handleClose={handleCloseImpressum} />
        <Datenschutz open={datenschutzOpen} handleClose={handleCloseDatenschutz} />
        <aside style={footerStyle}>© Created by Noah Skrzypczyk</aside>
        <aside className="clickable-footer" style={footerStyle} onClick={handleOpenImpressum}>
          Impressum
        </aside>
        <aside className="clickable-footer" style={footerStyle} onClick={handleOpenDatenschutzerklärung}>Datenschutzerklärung</aside>
      </Section>
    </div>
  );
}

export default MainView;
