import {
  Button,
  Card,
  CardContent,
  createMuiTheme,
  Dialog,
  DialogTitle,
  Modal,
  MuiThemeProvider,
  styled,
  Typography,
  useMediaQuery,
  useTheme,
  withStyles,
  Zoom
} from "@material-ui/core";
import {Clear, FullscreenExit} from "@material-ui/icons";
import React, {useState} from "react";
import Tooltip, {tooltipClasses, TooltipProps} from '@mui/material/Tooltip';


type CardProps = {
  image: any;
  title: string;
  description: string;
  textDokumentation?: string | JSX.Element;
};

function InfoCard(props: CardProps) {
  const dialogTheme = useTheme();
  const [open, setOpen] = React.useState<boolean>(false);
  const [imageModal, setImageModal] = React.useState<boolean>(false);
  const [fullscreen, setFullscreen] = useState(useMediaQuery(dialogTheme.breakpoints.down("md")));
  const expandedCard = React.useRef(null);
  const style = {
    backgroundImage: `url(/${props.image.split("/")[3]})`,
  };
  const expandCard = () => {
    setOpen(true);
  };

  const closeCard = () => setOpen(false);

  const toggleResize = () => setFullscreen(!fullscreen);

  const ImageModal = () => {
    return (
      <Modal open={imageModal} onClose={() => setImageModal(false)}>
        <img
          onClick={() => setImageModal(false)}
          src={props.image}
          style={{
            width: "100%",
            display: "block",
            margin: "0 auto",
            position: "absolute",
            top: "50%",
            msTransform: "translateY(-50%)",
            transform: "translateY(-50%)",
            cursor: "pointer",
          }}
        />
      </Modal>
    );
  };

  const ButtonDialogClose = styled(Button)({
    "&:hover": {
      backgroundColor: "#ff6e6b"
    }
  })

  const ButtonDialogResize = styled(Button)({
    "&:hover": {
      backgroundColor: "#1fc900"
    }
  })

  const __TooltipStyle = styled(({ className: cn, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: cn }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: '#f5f5f9',
      color: 'rgba(0, 0, 0, 0.87)',
      maxWidth: 220,
      fontSize: theme.typography.pxToRem(12),
      border: '1px solid #dadde9',
    }
  }));

  const CustomTooltip = (props: TooltipProps | any) =>
    <__TooltipStyle {...props} TransitionComponent={Zoom} className="notransition" followCursor />


  const PopUp = () => {
    const theme = createMuiTheme({
      typography: {
        fontFamily: "Rubik",
      },
    });

    const StyledCard = withStyles({
      root: {
        overflowY: "scroll",
        scrollbarWidth: "thin",
        height: "100%",
      },
    })(Card);



    return (
      <Dialog
        className="card-popup"
        open={open}
        scroll="paper"
        fullScreen={fullscreen}
        keepMounted
        closeAfterTransition={false}

      >
        <MuiThemeProvider theme={theme}>
          <DialogTitle style={{ padding: "0 0" }} id="customized-dialog-title">
              <ButtonDialogClose onClick={() => setOpen(false)} style={{ float: "right" }}>
                <Clear onClick={closeCard} />
              </ButtonDialogClose>
              <ButtonDialogResize onClick={toggleResize} style={{ float: "right" }}>
                <FullscreenExit onClick={toggleResize} />
              </ButtonDialogResize>
          </DialogTitle>
          <StyledCard className="info-card">
            <CustomTooltip enterDelay={2500} leaveDelay={200} title="Bild maximieren">
              <img
                src={props.image}
                style={{
                  maxHeight: "200px",
                  margin: "0 auto",
                  display: "block",
                  cursor: "pointer",
                }}
                onClick={() => setImageModal(true)}
              />
            </CustomTooltip>

            <CardContent>
              <Typography gutterBottom variant="h4">
                {props.title}
              </Typography>
              <Typography gutterBottom variant="h5">
                {props.description}
              </Typography>
              <Typography variant="body1">
                {props.textDokumentation}
              </Typography>
            </CardContent>
          </StyledCard>
        </MuiThemeProvider>
      </Dialog>
    );
  };

  return (
    <>
      <CustomTooltip title="Klick mich!">
        <div className={"blog-card"} onClick={expandCard} ref={expandedCard}>
          <img className="blog-image" style={style} />
          <div className="blog-description">
            <h5>{props.title}</h5>
            <p className="card-short-description">{props.description}</p>
          </div>

          {props.textDokumentation ? (
            <div className={"hidden"}>
              <p className="card-documentation">{props.textDokumentation}</p>
            </div>
          ) : undefined}
          {/* <a href="#">
        <div className="blog-social-icons">
        
        </div>
      </a> */}
        </div>
      </CustomTooltip>
      <PopUp />
      <ImageModal />
    </>
  );
}



export default InfoCard;
