import React from 'react';
import { type } from 'os';

type SectionProps = {
  children?:any,
  /**sectionClass -> z.B. cv, pf*/
  sectionId?:string
}
function Section(props:SectionProps){
    return (
        <div id = {props.sectionId} className="section">
          {props.children}
        </div>
      );
}

export default Section;