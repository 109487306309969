import { Fab } from "@material-ui/core";
import { NightsStay, Flare } from "@material-ui/icons";
import React from "react";
import { Helmet } from "react-helmet";
import { BrowserRouter as Router, Route } from "react-router-dom";
import "./App.css";
import MainView from "./components/mainview";
import Sidebar from "./components/sidebar";
import TitleView from "./components/titleView";

function Home() {

  const keyDarkmode = "nskdarkmode";
  const [darkmode, setDarkmode] = React.useState(false)

  React.useEffect(() => {
    const ls = localStorage.getItem(keyDarkmode);
    if (!ls) setDarkmode(false)
    else {
      const mode = JSON.parse(ls);
      if (mode) {
        setDarkmode(true)
      }
      else {
        setDarkmode(false)
      }
    }
  }, [])

  React.useEffect(() => {
    if (darkmode) {
      document.body.classList.add(keyDarkmode)
    }
    else {
      document.body.classList.remove(keyDarkmode)
    }
    localStorage.setItem(keyDarkmode, JSON.stringify(darkmode));
  }, [darkmode])

  return (
    <div className={"App"}>
      <Helmet>
        <meta charSet="utf-8" />
        <meta
          name="description"
          content="Persönliche Blog- und Portfolioseite"
        />
        <title>Noah Sk. M.Sc. | Developer</title>
        <link rel="canonical" ref="https://noahsk.de"></link>
      </Helmet>
      <TitleView
        id="about"
        name="Skrzypczyk"
        summary="M.Sc. Informatik | Fullstack Entwicklung | Data Mining"
        firstname="Noah"
        email="skrzypczyk.noah@gmail.com"
      ></TitleView>
      <MainView />
      <Sidebar name="Noah Sk" />
      <Fab onClick={() => setDarkmode(!darkmode)} style={{ bottom: "10px", right: "10px", position: "fixed", zIndex: 100 }}>{darkmode ? <Flare /> : <NightsStay />}</Fab>
    </div>
  );
}

function App() {
  return (
    <Router>
      <Route path="/" exact component={Home} />
    </Router>
  );
}

export default App;
