import React, { MutableRefObject, useRef } from 'react';
import { off } from 'process';
type Sidebarprops = {
  name:string
  idlink?:string
  hook?: Function
}

function SidebarEntry(props:Sidebarprops): any {

    function onClickfun(){
      if(props.idlink === "#"){
        window.scrollTo({top:0,behavior:"smooth"});
      }
      else if(props.idlink){
        const elem:HTMLElement= document.getElementById(props.idlink.replace("#",""))!
        const offset:number = elem!.offsetTop!
        window.scrollTo({top:offset-70,behavior:"smooth"});
      }
      if(props.hook!) props.hook!()
    }
    return (
      <li className={`nav-item nav-link ${props.idlink==="#"? "main-item": undefined}`} onClick={onClickfun}>
        {props.name}
      </li>
    );
}
  
  export default SidebarEntry;