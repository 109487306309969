import React from "react";
export interface ICvEntry {
  jobTitle?: string;
  company: string;
  entryText?: string;
  dateRange: string; // zB Mai 2019 - heute
}

export interface ICardEntry {
  image?: string;
  title: string;
  shortDescription: string;
  textDokumentation?: string | JSX.Element;
}
export default class Content {
  static readonly publicDir: string = window.location.origin;
  static readonly softHyphen = "\u00AD";
  static readonly styleLinks: React.CSSProperties = {
    fontSize: "1rem",
    textDecoration: "none",
    color: "#5c00ff",
  };
  public static profilePic = (id: string = "myimg") => {
    return (
      <img
        id={id}
        src="https://avatars1.githubusercontent.com/u/49651456?s=400&u=358f4df877e71294504a3baadf795e963cd54de3&v=4"
        className="profile-picture"
        alt="logo"
      />
    );
  };

  public static cvEntries: ICvEntry[] = [
    {
      jobTitle: "Softwareentwickler",
      company:"Soptim AG",
      entryText: "Fullstack-Entwicklung: Software für Übertragungsnetzbetreiber",
      dateRange: "2023 - heute"
    },
    {
      jobTitle: "Junior Software Engineer",
      company: "GBTEC Software AG",
      entryText: "Standardentwicklung",
      dateRange: "2022 - 2023"
    },
    {
      jobTitle: "Werkstudent",
      company: "GBTEC Software AG",
      entryText: "Entwicklung und Wartung von E2E-Tests",
      dateRange: "2019 - 2022",
    }
  ];
  public static eduEntries: ICvEntry[] = [
    {
      company: "Studium: Informatik M.Sc. \nHochschule Bochum\nMA-Note 1,3",
      dateRange: "2021 - 2022",
    },
    {
      company: "Studium: Informatik B.Sc. \nHochschule Bochum\nBA-Note 1,7",
      dateRange: "2017 - 2021",
    },
    {
      company: "Studium: Maschinenbau B.Sc. \nRuhr-Uni-Bochum",
      dateRange: "2015 - 2017",
    },
    {
      company: `Abitur \nNeues Gymnasium Bochum`,
      dateRange: "2015",
    },
  ];

  public static techStackEntries: ICvEntry[] = [
    {
      jobTitle: "Frontend",
      entryText: "HTML, CSS, React, JavaFX, Ext.js",
      company: "",
      dateRange: "",
    },
    {
      jobTitle: "Backend",
      entryText: "Node.js, Express, Flask",
      company: "",
      dateRange: "",
    },
    {
      jobTitle: "Tools",
      entryText:
        "VSCode, IntelliJ, Git(Hub), Jira, Terminal (bash), Linux (OpenSUSE | Manjaro | Fedora), Remmina (remote desktop client), screen (terminal multiplexer)",
      company: "",
      dateRange: "",
    },
    {
      jobTitle: "Datenbanken",
      entryText: "SQL (Postgres), (MongoDB)",
      company: "",
      dateRange: "",
    },
    {
      jobTitle: "Programmier" + Content.softHyphen + "sprachen etc.",
      entryText: "JavaScript, Python, Java 8 / 11, bash, TypeScript",
      company: "",
      dateRange: "",
    },
    {
      jobTitle: "Machine Learning / Data Analytics",
      entryText: "Association Rule Mining (Algorithmen und fortgeschrittene Konzepte), Jupyter Notebook, pandas, scipy, plotly, sklearn, Korrelationsanalyse",
      company: "",
      dateRange: ""
    },
    {
      jobTitle: "weitere Tools",
      entryText:
        "Protractor, Selenium, LaTeX (MikTeX), Android (Android Studio, Java | Kotlin)",
      company: "",
      dateRange: "",
    },
  ];

  public static portfolioEntries: ICardEntry[] = [
    {
      image: Content.publicDir + "/digiclock_GoL.gif",
      title: "pyLEDControl (2023-heute)",
      shortDescription: "64x64 Display mit Fernsteuerung",
      textDokumentation: (<>
        Nachdem ich im Jahr 2023 umgezogen bin, schaute ich nach Pixel-Art-Displays, um mein Arbeitszimmer zu dekorieren. Da ich aber die verfügbaren Gesamtlösungen zu teuer fand,
        begann ich selbst mit der Entwicklung einer solchen Anzeige.<br />

        So begann ich zunächst die benötigten Komponenten einzukaufen. Dazu gehörten ein Raspberry Pi 3b+, der notwendige Adafruit Hat als Kompatibilitäts-Layer, ein 30W Netzteil sowie die 64x64 RGB Matrix.
        In erster Linie sollte sie die Uhrzeit und das aktuelle Wetter oder eine Wettervorhersage anzeigen.
        Um das Rad nicht neu erfinden zu müssen, werden als Basis die Python-Bindings des hzeller/rpi-rgb-led-matrix Repository genutzt. <br />

        Nach und nach fing ich an, die vorhandenen Ebenen zu abstrahieren (Zustandsautomat, Kommunikation zw. Server und LED-Controller), wodurch es unter anderem
        möglich wurde, reibungslos zwischen einer emulierten Version des Boards (zu Entwicklungs-/Debuggingzwecken) und dem realen Deployment zu wechseln sowie eigene, neue Funktionalitäten
        einzubauen.
        Dazu gehört auch das „Kreieren“ neuer sogenannter „Effects“, also Ausführungsmodi für die Matrix.
        Eine Dokumentation zur aktuellen Architektur ist in Arbeit ;) <br />

        Spätestens während der Implementierung von Conways Game of Life war die mangelhafte Performance des Python-Interpreters spürbar, weshalb ich alte C-Bücher ausgepackt und den Algorithmus in diese Sprache übersetzt habe. Die C-Libs werden praktischerweise vom Setup-Script mittels make generiert. Lediglich die notwendigen Dependencies muss der Nutzer selbst installieren.

        <br />
        <br />
        Um die Anzeige zu Hause auch von meinem Smartphone aus steuern zu können, habe ich mithilfe von React und dem Material-UI-Framework eine Web-App gebaut, die ebenfalls im Rahmen des Projekts auf dem Raspi lokal gehostet wird. Mit der App ist es möglich, einen Effekt auszuwählen und die Helligkeit einzustellen.

        <br />
        <br />
        Nach der Einführung des „Spotify Effects“, welcher das aktuelle Album-Art und das abgespielte Lied samt Interpreten visualisiert, begann der erste breite architekturelle Umbau des Codes.

        Der Grund für diesen Schritt war, dass es keine Möglichkeit gab, einem ausgewählten Effect Parameter zu übergeben, die die Ausführung in irgendeiner Weise beeinflussen.
        Deshalb wurde auf abstrakter Ebene eine „Options“ Subklasse eingeführt, die innerhalb der jeweiligen Effect-Klasse implementiert werden kann, sofern zusätzliche Parameter (neben bspw. Helligkeit) spezifizierbar sein sollten.
        Um diese Parameter, abgebildet als statische Attribute, in ihren Werten eingrenzbar zu machen, wurden sog. Constraints eingeführt.
        Mithilfe dieser Options-Klasse war es nun möglich, eine JSON-Definition für das Frontend zu generieren, welche vorschreibt, welche Constraint-Kontrollstrukturen wie Slider oder Auswahlfenster für den ausgewählten Effekt angezeigt werden sollen. Dies erforderte eine ausführliche Auseinandersetzung mit Pythons Reflections, besonders in Zusammenhang mit dem Dataclasses-Modul, welches in einigen Klassen zum Einsatz gekommen ist.
        <br />
        <br />
        Weiterhin wollte ich die Möglichkeit haben, über das Frontend zusätzliche Effekte in Form von jpg/png/gif-Dateien bereitzustellen.
        Beim Upload des Mediums wird serverseitig neben der Ablage in ein lokales Verzeichnis zusätzlich ein yaml-File erstellt, welches Metadaten wie den Namen des Effects, mögliche Constraints (Platzhalter für Zukunft) und den Dateipfad der Quelldatei enthält.
        Handelt es sich um eine gif-Datei, so hat es sich in der Praxis bewiesen, die einzelnen Frames spätestens vor dem ersten Start des neuen Effects zu transformieren und zu pickeln.
        Grund dafür ist der relativ hohe Berechnungsaufwand der Bildskalierung und der Extraktion der gif-Frames.

        <br />
        <br />
        Ein Problem, dem noch nachgegangen werden muss, ist das gelegentliche Ghosting bzw. Flackern abwechselnder Bereiche der Matrix.
        Vermutet wird diesbezüglich, dass es sich hierbei um Spannungsschwankungen der PSU, welche gleichzeitig den Raspi sowie die Matrix mit Strom versorgt.
      </>)
    },
    {
      image: Content.publicDir + "/frontend-backend-diagramm.png",
      shortDescription: "Mobile visuelle Datenanalyse",
      title: "Bachelorarbeit 2020/21",
      textDokumentation:
        "In Zeiten von zunehmender Automatisierung, Industrie 4.0 und Digitalisierung ist die Datenanalyse zu einem wichtigen Prozess geworden, der für Unternehmen essenziell ist, um weiterhin innovativ und konkurrenzfähig zu sein. Mit dem stetigen Austausch von klassischen Desktoprechnern mit mobilen Endgeräten wie Tablets und Smartphones hat sich auch die visuelle Datenanalyse auf solchen Geräten zu einer Notwendigkeit entwickelt. Mithilfe von modernen Webtechnologien und Visualisierungsbibliotheken ist es heutzutage möglich, die visuelle Datenanalyse geräteübergreifend zu verwirklichen. \nZiel der Bachelorarbeit ist es, eine Lösung auszuarbeiten, um Daten möglichst performant auf mobilen Endgeräten im Rahmen eines Dashboards zu visualisieren. Das dabei entstehende Dashboard wird in Abhängigkeit der genutzten Technologien und seiner Leistungsfähigkeit mit dem open-source Datenanalyse-Werkzeug Grafana gegenübergestellt. Dabei festzustellende Differenzen sind dabei ebenfalls von Interesse. Die Web-Applikation soll nach Implementierung hinsichtlich vielfältiger leistungsbasierten Metriken analysiert werden, um mögliche Bottlenecks lokalisieren und beseitigen zu können. Dabei kommen moderne, automatische open-source Tools zum Einsatz, deren Zuverlässigkeit, Genauigkeit sowie Integrierbarkeit analysiert und evaluiert werden.",
    },
    {
      image: Content.publicDir + "/botex-screenshot.png",
      title: "BoTeX",
      shortDescription: "Semesterprojekt 2019/2020",
      textDokumentation:
        "BoTeX ist ein Editor zum Erstellen und Bearbeiten von Bachelorarbeiten basierend auf dem Schriftsatzsystem LaTeX. Dabei ermöglicht der Editor, schon bestehenden LaTeX-Code einzulesen und graphisch anzuzeigen. Dazu wird die Library ANTLR verwendet, welche Tokenlisten und einen Syntaxtree generiert. Mit diesen wird der LaTeX-Code in Java-Klassen übersetzt. Da BoTeX auf Bachelorarbeiten fokussiert ist, bietet der Editor die Möglichkeit ein vorgefertigtes Titelblatt und ein Inhaltsverzeichnis einzufügen. Die Daten des Titelblatts können dabei entsprechend angepasst werden. Aus dem fertigen Dokument wird während des Speicherns LaTeX-Code generiert. Somit kann der Code dann noch weiter bearbeitet werden oder durch die LaTeX-PDF Tools (latexmk) verarbeitet werden.",
    },
    {
      image: Content.publicDir + "/personal-homepage-screenshot.png",
      title: "Persönliche Webseite",
      shortDescription:
        "Implementierung einer Personal Homepage mithilfe von React und TypeScript",
      textDokumentation:
        "Mitte des Jahres 2020 begann ich die Idee zu verwirklichen, meine eigene persönliche Webseite zu kreieren. Dies diente vor allem zur Festigung meiner HTML- und CSS-Kenntnisse. Um den Entwicklungsprozess zu vereinfachen wurde das Projekt mithilfe von 'create-react-app' auf TypeScript-Basis aufgesetzt. Da kurz darauf meine Bachelorarbeits- beziehungsweise Praxisphase begann musste dieses Projekt bis zum Januar 2021 pausiert werden. Kurz nach Abgabe meiner Bachelorabeit konnte die Website verfeinert werden und ging mithilfe von Netlify online.",
    },
    {
      image: Content.publicDir + "/pywellbeing.png",
      title: "pyDigitalWellbeing",
      shortDescription: "Tracking der Desktopaktivität mittels Python",
      textDokumentation:
        '2020 / 21 :: Die Funktion "Digital Wellbeing" ist beispielweise unter Android nützlich, um die eigene Bildschirmzeit zu überwachen und statistisch festzuhalten, wieviel Zeit in jener App verbracht wurde. Diese Funktion wollte ich gerne, wenn möglich, plattform-unabhängig nachbilden. Da die Window Management Systeme von Windows und Unix-ähnlichen Systemen äußerst unterschiedlich sind, musste ich bei der Implementierung des Trackers auf plattformspezifische Tools zurückgreifen. Bei der Nutzung unter Linux kommt daher das Programm "xprop" zum Einsatz, um Fensterinformationen zu erhalten. Auf Windows hingegen kommt die Python-Bibliothek "win32gui" zum Zug, um den Fenstertitel auszulesen. Falls unter Windows kein Titel erkannt werden konnte, wird als Fallback mittels "ctypes" die ProzessId ermittelt, um nachher über "psutil" den Anwendungsnamen des Prozess zu erlangen. Der Tracker prüft den aktuellen Status in der Regel alle 2 Sekunden und legt seine Ergebnisse in eine SQLite Datenbank. Diese dient als Basis für die Visualisierung, da auch das auf "Dash" (Plotly) basierende Frontend auf die Daten zugreift und diese als Kuchen- sowie Balkendiagramme darstellt.',
    },
    {
      image: Content.publicDir + "/hsbo-fc-screenshot.png",
      title: "HSBO Flashcards",
      shortDescription:
        "Cross-Platform-Karteikarten-App mithilfe von React, Node.js und Docker",
      textDokumentation: (
        <>
          Die Applikation 'HSBO Flashcards' enstand im Sommersemester 2021 als
          Semesterprojekt des Moduls 'Web-Engineering' des Master-Studiums an
          der Hochschule Bochum in Zusammenarbeit mit dem Kommilitonen{" "}
          <a style={Content.styleLinks} href="http://timkodiert.de">
            Tim Poerschke
          </a>{" "}
          und dient als Plattform für das Lernen mit Karteikarten. Zumal das
          Programm Cloud-basiert und damit über den üblichen Webbrowser
          erreichbar ist, ist es unerheblich, welche Platform - Tablet,
          Smartphone, PC etc. - genutzt wird.{"\n\n"}Besonderheiten sind hierbei
          die Möglichkeit, die Karteikartenrückseite auch mit einem Stift
          (Stylus) zu beschriften, sofern das Tablet bzw. Smartphone auch eine
          solche Eingabe unterstützt. Als Basis für die Funktion dient das
          NPM-Modul 'ReactSketchCanvas', welches SVG-basiert ist.{"\n\n"}Als
          Grundlage für das Frontend des Projekts entschieden wir uns für React
          auf TypeScript-Basis. Zusätzlich kam das Material-UI-Framework zum
          Einsatz, mit dem ein Großteil der Elemente gestyled wurde.{"\n\n"}
          Für das Backend wurden Express.js sowie TypeORM in Verbindung mit
          einem Postgres Datenbankserver (Docker-Container) eingesetzt. Vor
          allem die Nutzung von TypeORM war für mich neu, aber auch eine gute
          Erfahrung, zumal dadurch Daten aus der Datenbank als Objekte abgerufen
          werden können.
          {"\n\n"}Weitere Besonderheiten unserer Arbeit ist die komplette
          Dockerisierung des Front-und Backends, die Möglichkeit für Nutzer,
          ihre Karteikarten-Stacks mit anderen Nutzern zu teilen sowie die
          Implementierung eines Amazon-Alexa-Skills, mit dem Nutzer einen
          Karteikarten-Test mithilfe eines Alexa-fähigen Geräts durchführen
          können.
        </>
      ),
    },
  ];

  // constructor(){
  //     this.cvEntries.push(

  //     );
  // }
}
