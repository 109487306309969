import React from 'react';
import Section from './section';

type ChapterTitleProps = {
    name: string
}

function ChapterTitle(props:ChapterTitleProps){
    return (
        <h2 className="chap-title">
            {props.name}
        </h2>
      );
}

export default ChapterTitle;